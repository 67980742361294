import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { CiLogin } from "react-icons/ci";

export default function Header() {
  return (
    <>
      <header className="header-area">
        <div className="top-bar-one dark-black-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-12 col-md-12 col-7">
                <div className="top-left d-flex align-items-center">
                  <span className="text text-white">
                    Unisafe Digital & Solar Energy
                  </span>
                  <span
                    className="lang-dropdown text-white"
                    style={{ textTransform: "uppercase" }}
                  >
                    Safety is not Expensive.It is Priceless
                  </span>
                  {/* <span className="test ">Safety is not Expensive.It is Priceless</span> */}
                </div>
              </div>
              <div className="col-xl-5 col-lg-12 col-md-12 col-5">
                <div className="top-right">
                  <span className="text text-white">
                    <i className="flaticon-phone-call text-white"></i>
                    <a href="tel:7340044005">+91 73-4004-4005</a>
                  </span>
                  <ul className="social-link">
                    <li>
                      <a
                        href="https://www.facebook.com/UNISAFEDIGITAL"
                        target="blank"
                      >
                        <i>
                        <FaFacebookF size={20} color="#fff" />
                        </i>
                        
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/unisafedigital/"
                        className="text-white"
                        target="blank"
                      >
                        <i>
                        <FaInstagram size={20} color="#fff" />
                        </i>
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://api.whatsapp.com/send?phone=7340044005&text=Hello this is the starting message"
                        target="blank"
                        className="text-white"
                      >
                        <i>
                        <FaWhatsapp size={20} color="#fff" />
                        </i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-navigation navigation-two">
          <div className="nav-overlay"></div>
          <div className="container">
            <div className="primary-menu">
              <div className="site-branding ">
                <Link to="/" className="brand-logo">
                  <img
                    src="assets/images/logo/logo.webp"
                    width="100%"
                    alt="Logo"
                  />
                </Link>
              </div>
              <div className="nav-inner-menu">
                <div className="nav-menu">
                  {/* <!-- Navbar Close --> */}
                  <div className="navbar-close">
                    <i className="far fa-times"></i>
                  </div>
                  {/* <!-- Navbar Call Button --> */}
                  <div className="call-button">
                    <span>
                      <i className="flaticon-phone-call"></i>
                      <a href="tel:+91 7340044005">+91 73-4004-4005</a>
                    </span>
                  </div>
                  <nav className="main-menu">
                    <ul>
                      <li className="menu-item">
                        <Link to="/" className="active">
                          Home
                        </Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/about">About Us</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/solarplant">Solar</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/products">Products</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/gallary">Gallary</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/complain-register">Complain Register</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/contactUs">Contact Us</Link>
                      </li>
                      <li>
                      
                        <Link to="/admin"><CiLogin />Login</Link>
                      </li>
                    </ul>
                  </nav>
                  {/* <!-- Navbar Menu Button --> */}
                </div>

                <div className="nav-right-item d-flex align-items-center">
                  <div className="navbar-toggler">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

// import React from "react";
// import { Link } from "react-router-dom";
// import { FaFacebookF, FaInstagram, FaWhatsapp, FaHome, FaInfo, FaBox, FaImages, FaPhone } from "react-icons/fa";
// import './Header.css'; // External CSS for styling

// export default function Header() {
//   return (
//     <>
//       <header className="header-area">
//         <div className="top-bar-one dark-black-bg">
//           <div className="container">
//             <div className="row align-items-center">
//               <div className="col-xl-7 col-lg-12 col-md-12 col-7">
//                 <div className="top-left d-flex align-items-center">
//                   <span className="text text-white">Unisafe Digital & Solar Energy</span>
//                   <span className="lang-dropdown text-white" style={{ textTransform: "uppercase" }}>
//                     Safety is not Expensive. It is Priceless
//                   </span>
//                 </div>
//               </div>
//               <div className="col-xl-5 col-lg-12 col-md-12 col-5">
//                 <div className="top-right">
//                   <span className="text text-white">
//                     <i className="flaticon-phone-call text-white"></i>
//                     <a href="tel:7340044005">+91 73-4004-4005</a>
//                   </span>
//                   <ul className="social-link">
//                     <li>
//                       <a href="https://www.facebook.com/UNISAFEDIGITAL" target="blank">
//                         <i><FaFacebookF size={20} color="#fff" /></i>
//                       </a>
//                     </li>
//                     <li>
//                       <a href="https://www.instagram.com/unisafedigital/" className="text-white" target="blank">
//                         <i><FaInstagram size={20} color="#fff" /></i>
//                       </a>
//                     </li>
//                     <li>
//                       <a href="https://api.whatsapp.com/send?phone=7340044005&text=Hello this is the starting message" target="blank" className="text-white">
//                         <i><FaWhatsapp size={20} color="#fff" /></i>
//                       </a>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="header-navigation navigation-two">
//           <div className="nav-overlay"></div>
//           <div className="container">
//             <div className="primary-menu">
//               <div className="site-branding">
//                 <Link to="/" className="brand-logo">
//                   <img src="assets/images/logo/logo.webp" width="100%" alt="Logo" />
//                 </Link>
//               </div>
//               <div className="nav-inner-menu">
//                 <div className="nav-menu">
//                   <div className="navbar-close">
//                     <i className="far fa-times"></i>
//                   </div>
//                   <div className="call-button">
//                     <span>
//                       <i className="flaticon-phone-call"></i>
//                       <a href="tel:+91 7340044005">+91 73-4004-4005</a>
//                     </span>
//                   </div>
//                   <nav className="main-menu">
//                     <ul>
//                       <li className="menu-item"><Link to="/" className="active">Home</Link></li>
//                       <li className="menu-item"><Link to="/about">About Us</Link></li>
//                       <li className="menu-item"><Link to="/products">Products</Link></li>
//                       <li className="menu-item"><Link to="/gallary">Gallary</Link></li>
//                       <li className="menu-item"><Link to="/contactUs">Contact Us</Link></li>
//                     </ul>
//                   </nav>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Mobile Bottom Navigation */}
//         <div className="mobile-bottom-nav">
//           <Link to="/" className="mobile-nav-link"><FaHome size={20} /><span>Home</span></Link>
//           <Link to="/about" className="mobile-nav-link"><FaInfo size={20} /><span>About</span></Link>
//           <Link to="/products" className="mobile-nav-link"><FaBox size={20} /><span>Products</span></Link>
//           <Link to="/gallary" className="mobile-nav-link"><FaImages size={20} /><span>Gallery</span></Link>
//           <Link to="/contactUs" className="mobile-nav-link"><FaPhone size={20} /><span>Contact</span></Link>
//         </div>
//       </header>
//     </>
//   );
// }
